import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { AwareAuthService } from '@appbolaget/aware-auth';
import { map } from 'rxjs';

@Injectable()
export class GuestGuard {
    private awareAuth = inject(AwareAuthService);
    constructor(private router: Router) {}

    canActivate() {
        return this.awareAuth.isAuthenticated$.pipe(
            map((isAuthenticated) => {
                if (isAuthenticated) {
                    this.router.navigate(['/app/dashboard']);
                    return true;
                }

                return false;
            }),
        );
    }
}
